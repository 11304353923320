import { BigNumber, BigNumberInBase } from '@injectivelabs/utils'

export * from './setup'

export const INJ_REQUIRED_FOR_GAS = 0.005
export const DEFAULT_COSMOS_GAS_PRICE = 0.01
export const POLL_USD_PRICE_INTERVAL = 1000 * 30
export const DEFAULT_DECIMAL_PLACES = 4
export const MINIMUM_DECIMAL_PLACES = 2

export const UI_DEFAULT_MINIMAL_AMOUNT = new BigNumber(1).shiftedBy(
  -MINIMUM_DECIMAL_PLACES
)

export const TX_DEFAULTS_GAS = 80_000_000
export const GWEI_IN_WEI: BigNumber = new BigNumber(1000000000)
export const TIP_IN_GWEI: BigNumberInBase = new BigNumberInBase(2).times(
  GWEI_IN_WEI
)
export const TIP_IN_GWEI_TESTNET: BigNumberInBase = new BigNumberInBase(
  1.5
).times(GWEI_IN_WEI)
export const GAS_LIMIT_MULTIPLIER = 1.2

export const UNLIMITED_ALLOWANCE: BigNumber = new BigNumber(2).pow(256).minus(1)
export const ETH_BRIDGE_FEE_IN_USD = 5
