export default {
  transfer: {
    title: 'Transfer',
    migrate: 'Migrate',
    bridge: 'Injective Bridge.',
    successful: 'Transfer Successful',
    enterAddress: 'Enter Injective Address',
    selectAsset: 'Select asset to transfer',
    migrateDescription: 'Convert legacy Wormhole assets',
    sendAsset: 'Send asset to another Injective wallet',
    convertToBank:
      'Click here if you wish to convert this CW20 token to a bank token.',
    doubleCheck:
      'Please check the address. Tokens sent to a wrong address cannot be recovered.',
    convertToBankTooltip:
      'Check this box to convert this CW20 token to a bank token during transfer. You can reconvert to CW20 anytime on the Injective Hub Wallets page.',
    crossChainTransfer: 'For cross-chain transactions, please use the {bridge}',
    noteMultipleSign:
      "There will be multiple sign confirmations. Please stay on this page until the transfer is completed. If your transaction is stuck, visit {link}, search your Transaction Hash and click on Resume Transaction. If there is no way to resume the transaction from wormhole scan and you still haven't received the tokens, use the {advancedToolsLink} and try to resume the transaction from there",

    memo: {
      title: 'Memo',
      required: 'Required',
      placeholder: 'Enter memo (required for most centralized exchanges)'
    }
  }
}
