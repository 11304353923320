<script lang="ts" setup>
import { Status } from '@injectivelabs/utils'

const attrs = useAttrs()

const props = defineProps({
  isXs: Boolean,
  isSm: Boolean,
  isLg: Boolean,
  isPrimary: Boolean,
  isLoading: Boolean,
  isDisabled: Boolean,

  status: {
    type: Object as PropType<Status>,
    default: () => new Status()
  }
})

const emit = defineEmits<{
  click: [event: Event]
}>()

const isSpinnerVisible = computed<boolean>(
  () => (props.status && props.status.isLoading()) || props.isLoading
)

const presetClasses = computed(() => {
  if (props.isPrimary) {
    return 'bg-gradient-to-r hover:bg-opacity-80 text-black'
  }

  return ''
})

const filteredAttrs = computed(() => {
  const filteredAttrs = { ...attrs }

  const classes = `${(filteredAttrs.class as string) || ''} ${
    presetClasses.value
  }`

  let formattedClasses = classes
    .replace(/hover:bg-(\w+)/g, '')
    .replace(/hover:bg-(\w+)-(\d+)/g, '')
    .replace(/hover:text-(\w+)/g, '')
    .replace(/hover:text-(\w+)-(\d+)/g, '')

  /** Remove text|bg color from buttons when they are disabled */
  if (props.isDisabled) {
    return {
      ...attrs,
      class: [
        formattedClasses
          .replace(/bg-gradient-to-[a-z]+/g, '')
          .replace(/bg-(\w+)-(\d+)/g, '')
          .replace(/text-(\w+)-(\d+)/g, ''),
        'text-gray-400 cursor-not-allowed border border-gray-650'
      ].join(' ')
    }
  }

  if (isSpinnerVisible.value) {
    if (!formattedClasses.includes('bg-opacity')) {
      formattedClasses = `${formattedClasses} bg-opacity-80`
    }

    if (!formattedClasses.includes('text-opacity')) {
      formattedClasses = `${formattedClasses} text-opacity-80`
    }

    if (!formattedClasses.includes('border-opacity')) {
      formattedClasses = `${formattedClasses} border-opacity-80`
    }

    return { ...attrs, class: `${formattedClasses} cursor-not-allowed` }
  }

  return { ...attrs, class: classes }
})

const sizeClasses = computed<string>(() => {
  if (props.isXs) {
    return 'px-2 text-xs h-6 max-h-6'
  }

  if (props.isSm) {
    return 'px-2 text-xs h-8 max-h-8'
  }

  return 'px-4 text-base h-10 max-h-10'
})

function click(event: Event) {
  if (props.status && props.status.isLoading()) {
    return
  }

  emit('click', event)
}
</script>

<script lang="ts">
export default {
  inheritAttrs: false
}
</script>

<template>
  <button
    type="button"
    class="flex items-center justify-center font-semibold outline-none rounded-md"
    :class="[sizeClasses]"
    :disabled="isDisabled || status.isLoading()"
    v-bind="filteredAttrs"
    @click="click"
  >
    <slot v-if="isSpinnerVisible" name="spinner">
      <span class="block w-full">
        <AppSpinner v-bind="{ isGray: isDisabled }" />
      </span>
    </slot>

    <span v-else class="first-letter:uppercase">
      <slot />
    </span>
  </button>
</template>
