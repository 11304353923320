<script setup lang="ts">
function onRedirectHome() {
  navigateTo('/')
}
</script>
<template>
  <div class="bg-black text-white font-sans text-base min-h-screen">
    <NuxtLayout is-error-page />
    <section
      class="flex flex-wrap flex-col justify-center items-center h-full w-full px-4 mt-16"
    >
      <h2 class="text-3xl font-semibold">{{ $t('notFound404.title') }}</h2>

      <p>{{ $t('notFound404.description') }}</p>
      <AppButton class="mt-6" is-primary @click="onRedirectHome">
        {{ $t('notFound404.backToHome') }}
      </AppButton>
    </section>
  </div>
</template>
