import { Wallet } from '@injectivelabs/wallet-base'

export default {
  wallet: {
    title: 'Connect a wallet',
    qrCode: 'QR Code',
    address: 'Address',
    connect: 'Connect',
    popular: 'Popular',
    disconnect: 'Disconnect',
    otherWallets: 'Other Wallets',
    connectWallet: 'Connect wallet',
    connectedEthWallet: 'connected Ethereum wallet',
    changeWallet: 'Change Wallet',
    ethereumAddress: 'Ethereum Address',

    connectUsingTorus: 'One-click login with',
    connectUsingBrowser: 'Connect using a browser wallet',
    connectUsingHardware: 'Connect using a hardware wallet',

    successfullyConnected: 'Successfully Connected',

    hardwareWallet: {
      getAddresses: 'Get addresses',
      getMoreAddresses: 'Get more addresses',
      selectAddressToConnect: 'Select address to connect',
      getAddressNote: 'We are getting your addresses, please wait ...'
    },

    trezor: {
      trezor: 'Trezor',
      trezorBip32: 'Trezor',
      trezorBip44: 'Trezor Bip44',
      derivationPath: 'Derivation Path',
      selectDerivationPath: 'Select Derivation Path',
      note: 'Note: To ensure smooth process while connecting your Trezor Hardware Wallet, please ensure you are running the on latest Chrome version, have your Trezor device connected and unlocked.'
    },

    ledger: {
      ledgerLive: 'Ledger Live',
      ledgerLegacy: 'Ledger Legacy',
      derivationPath: 'Derivation Path',
      selectDerivationPath: 'Select Derivation Path',
      note: 'Note: To ensure smooth process while connecting your Ledger Hardware Wallet, please ensure you are running the on latest Chrome version, have your Ledger device connected, unlocked and your Ethereum app open.'
    },

    option: {
      [Wallet.Leap]: 'Leap',
      [Wallet.Keplr]: 'Keplr',
      [Wallet.Ninji]: 'Ninji',
      [Wallet.Ledger]: 'Ledger',
      [Wallet.Phantom]: 'Phantom',
      [Wallet.Metamask]: 'Metamask',
      [Wallet.TrezorBip32]: 'Trezor',
      [Wallet.TrezorBip44]: 'Trezor Bip44',
      [Wallet.BitGet]: 'BitGet Wallet',
      [Wallet.OkxWallet]: 'OKX Wallet',
      [Wallet.TrustWallet]: 'Trust Wallet',
      [Wallet.Cosmostation]: 'Cosmostation',
      [Wallet.WalletConnect]: 'Wallet Connect'
    }
  }
}
