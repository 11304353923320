export enum MixpanelEvent {
  Login = 'Login',
  OnramperSeen = 'OnRamper Seen',
  ConnectClicked = 'Connect Clicked',
  BridgeAttempted = 'Bridge Attempted',
  OnramperSuccess = 'OnRamper Success',
  EvmosBridgeClicked = 'Evmos Bridge Clicked'
}

export enum MixPanelCounter {
  ConnectClicked = 'Connect Clicked',
  BridgeAttempted = 'Bridge Attempted',
  EvmosBridgeClicked = 'Evmos Bridge Clicked'
}
