import { TokenStatic } from '@injectivelabs/sdk-ts'
import { IS_DEVNET, IS_TESTNET, IS_MAINNET } from '@shared/utils/constant'
import gitVersion from './gitVersion.json'
import mainnetTokens from '@/app/json/tokens/mainnet.json'
import testnetTokens from '@/app/json/tokens/testnet.json'
import devnetTokens from '@/app/json/tokens/devnet.json'
import devnetSpotMarketIdMap from '@/app/json/marketMap/spot/devnet.json'
import testnetSpotMarketIdMap from '@/app/json/marketMap/spot/testnet.json'
import stagingSpotMarketIdMap from '@/app/json/marketMap/spot/staging.json'
import mainnetSpotMarketIdMap from '@/app/json/marketMap/spot/mainnet.json'
import restrictedCountriesJson from '@/app/json/restrictedCountries.json'
import blacklistedAddressesJson from '@/app/json/blacklistedAddresses.json'

const IS_STAGING = import.meta.env.VITE_ENV === 'staging'

export const getTokens = () => {
  if (IS_DEVNET) {
    return devnetTokens
  }

  if (IS_TESTNET) {
    return testnetTokens
  }

  return mainnetTokens
}

export const getVerifiedSpotMarketIdMap = () => {
  if (IS_DEVNET) {
    return devnetSpotMarketIdMap
  }

  if (IS_TESTNET) {
    return testnetSpotMarketIdMap
  }

  if (IS_MAINNET && IS_STAGING) {
    return stagingSpotMarketIdMap
  }

  return mainnetSpotMarketIdMap
}

export const restrictedCountries = restrictedCountriesJson
export const blacklistedAddresses = blacklistedAddressesJson

export const gitBuild = () => {
  return (
    gitVersion || {
      branch: 'master',
      tag: 'v0.0.0',
      gitTagLink: '',
      logs: []
    }
  )
}

export const tokens = getTokens() as TokenStatic[]
export const verifiedSpotMarketIdMap = getVerifiedSpotMarketIdMap()
