import { mixpanelAnalytics } from '@/app/providers/mixpanel/BaseTracker'
import { MixpanelEvent, MixPanelCounter } from '@/types'

export const bridgingAttempt = ({
  amount,
  originAddress,
  destinationAddress,
  originNetwork,
  destinationNetwork
}: {
  amount: string
  originAddress: string
  destinationAddress: string
  originNetwork: string
  destinationNetwork: string
}) => {
  mixpanelAnalytics.track(MixpanelEvent.BridgeAttempted, {
    amount,
    originAddress,
    destinationAddress,
    originNetwork,
    destinationNetwork
  })
  mixpanelAnalytics.increment(MixPanelCounter.BridgeAttempted)
}

export const trackEvmosBridgeClicked = ({
  symbol,
  originNetwork,
  destinationNetwork
}: {
  symbol: string
  originNetwork: string
  destinationNetwork: string
}) => {
  mixpanelAnalytics.track(MixpanelEvent.EvmosBridgeClicked, {
    symbol,
    originNetwork,
    destinationNetwork
  })
  mixpanelAnalytics.increment(MixPanelCounter.EvmosBridgeClicked)
}

export const trackOnramperSeen = (walletAddress: string) => {
  mixpanelAnalytics.track(MixpanelEvent.OnramperSeen, {
    'Wallet Address': walletAddress
  })
}

export const trackOnramperSuccess = ({
  network,
  orderId,
  fiatAmount,
  cryptoAmount,
  fiatCurrency,
  walletAddress,
  totalFeeInFiat,
  cryptoCurrency,
  partnerOrderId
}: {
  network: string
  orderId: string
  fiatAmount: string
  cryptoAmount: string
  fiatCurrency: string
  walletAddress: string
  totalFeeInFiat: string
  cryptoCurrency: string
  partnerOrderId: string
}) => {
  mixpanelAnalytics.track(MixpanelEvent.OnramperSuccess, {
    Network: network,
    'Wallet Address': walletAddress,
    'Order Id': orderId,
    'Partner Order Id': partnerOrderId,
    'Fiat Amount': fiatAmount,
    'Fiat Currency': fiatCurrency,
    'Crypto Amount': cryptoAmount,
    'Crypto Currency': cryptoCurrency,
    'Total Fee In Fiat': `${totalFeeInFiat} ${fiatCurrency}`
  })
}