
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as envtnDiNBIHj7Meta } from "/home/runner/work/injective-bridge/injective-bridge/pages/env.vue?macro=true";
import { default as external_45linkUQhY3EOelYMeta } from "/home/runner/work/injective-bridge/injective-bridge/pages/external-link.vue?macro=true";
import { default as fiat5lCkopcaMxMeta } from "/home/runner/work/injective-bridge/injective-bridge/pages/index/fiat.vue?macro=true";
import { default as indexYEbWPVPGpzMeta } from "/home/runner/work/injective-bridge/injective-bridge/pages/index/index.vue?macro=true";
import { default as wormholeHPSEHJWpD4Meta } from "/home/runner/work/injective-bridge/injective-bridge/pages/index/wormhole.vue?macro=true";
import { default as indexCHkFRL2OieMeta } from "/home/runner/work/injective-bridge/injective-bridge/pages/index.vue?macro=true";
import { default as maintenanceGKRr93j2JZMeta } from "/home/runner/work/injective-bridge/injective-bridge/pages/maintenance.vue?macro=true";
import { default as memes_45bridgex9Q9EH9iesMeta } from "/home/runner/work/injective-bridge/injective-bridge/pages/memes-bridge.vue?macro=true";
import { default as transfer1ZSyuq32tjMeta } from "/home/runner/work/injective-bridge/injective-bridge/pages/transfer.vue?macro=true";
import { default as wheBSHxlgtB9Meta } from "/home/runner/work/injective-bridge/injective-bridge/pages/wh.vue?macro=true";
import { default as wormhole_45migrationSWcPsaLv11Meta } from "/home/runner/work/injective-bridge/injective-bridge/pages/wormhole-migration.vue?macro=true";
export default [
  {
    name: "env",
    path: "/env",
    meta: envtnDiNBIHj7Meta || {},
    component: () => import("/home/runner/work/injective-bridge/injective-bridge/pages/env.vue")
  },
  {
    name: "external-link",
    path: "/external-link",
    meta: external_45linkUQhY3EOelYMeta || {},
    component: () => import("/home/runner/work/injective-bridge/injective-bridge/pages/external-link.vue")
  },
  {
    name: indexCHkFRL2OieMeta?.name,
    path: "/",
    component: () => import("/home/runner/work/injective-bridge/injective-bridge/pages/index.vue"),
    children: [
  {
    name: "index-fiat",
    path: "fiat",
    meta: fiat5lCkopcaMxMeta || {},
    component: () => import("/home/runner/work/injective-bridge/injective-bridge/pages/index/fiat.vue")
  },
  {
    name: "index",
    path: "",
    component: () => import("/home/runner/work/injective-bridge/injective-bridge/pages/index/index.vue")
  },
  {
    name: "index-wormhole",
    path: "wormhole",
    component: () => import("/home/runner/work/injective-bridge/injective-bridge/pages/index/wormhole.vue")
  }
]
  },
  {
    name: "maintenance",
    path: "/maintenance",
    meta: maintenanceGKRr93j2JZMeta || {},
    component: () => import("/home/runner/work/injective-bridge/injective-bridge/pages/maintenance.vue")
  },
  {
    name: "memes-bridge",
    path: "/memes-bridge",
    component: () => import("/home/runner/work/injective-bridge/injective-bridge/pages/memes-bridge.vue")
  },
  {
    name: "transfer",
    path: "/transfer",
    component: () => import("/home/runner/work/injective-bridge/injective-bridge/pages/transfer.vue")
  },
  {
    name: "wh",
    path: "/wh",
    meta: wheBSHxlgtB9Meta || {},
    component: () => import("/home/runner/work/injective-bridge/injective-bridge/pages/wh.vue")
  },
  {
    name: "wormhole-migration",
    path: "/wormhole-migration",
    component: () => import("/home/runner/work/injective-bridge/injective-bridge/pages/wormhole-migration.vue")
  }
]